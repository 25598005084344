Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleApiEndPoint = "EXAMPLE_URL";
exports.exampleApiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "VoiceMemos";
exports.labelBodyText = "VoiceMemos Body";
exports.voiceMemoWarnMessage="microphone access required to use this feature";
exports.closeButtonText = "Close";
exports.btnExampleTitle = "CLICK ME";
exports.voiceMemoApiEndPoint = "bx_block_voicememos/voicememos";
exports.deleteApiMethodType = "DELETE";
exports.savingConfirmationMessage="Do you want to save the recording?";
exports.deleteConfirmationMessage="Voice note will be deleted permanently. Are you sure?";
exports.btnSaveTitle = "Save";
exports.btnDiscardTitle = "Discard";
exports.btnYesTitle = "Yes";
exports.btnNoTitle = "No";
exports.createSuccessResp="Voicememo created successfully";
exports.voiceMemoMaxlimitMessage="Max recording limit reached";
exports.endMessage="Yay! You have seen it all";
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDMsImV4cCI6MTczMTU4MDE2MywibG9naW5fdGltZSI6MTY5OTk1Nzc2MywidG9rZW5fdHlwZSI6InJlZnJlc2gifQ.A3X0EVdeigrPyDOecseC5axurD_tfURJViDZWRo-svXLrkPHDVoqc-wWK_R1btn3VwRsT5skEqsrfq0u2pPVyQ";

// Customizable Area End

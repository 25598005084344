import React from "react";

// Customizable Area Start
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  DialogTitle,
  DialogActions,
  createTheme,
  ThemeProvider
} from "@material-ui/core";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseIcon from '@material-ui/icons/Pause';
import DeleteIcon from '@material-ui/icons/Delete';
import StopIcon from '@material-ui/icons/Stop';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { resumeIcon } from './assets'
import InfiniteScroll from 'react-infinite-scroll-component';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import VoiceMemosController, {
  Props, baseURL, VoiceMemo, configJSON
} from "./VoiceMemosController.web";

// Customizable Area End



export default class VoiceMemos extends VoiceMemosController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  VoicememoList = (voiceMemoslist: VoiceMemo[]) => {
    return (
      <div className="images" id="scrollableDiv" style={{ height: "64vh", overflowY: "scroll", scrollbarWidth: "none" }}>
        <InfiniteScroll
          dataLength={this.state.voiceMemoslist.length}
          next={this.loadFunc}
          data-testid="onscroll"
          hasMore={true}
          loader={<h4></h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>{configJSON.endMessage}</b>
            </p>
          }
          scrollableTarget="scrollableDiv"
          style={{ marginTop: '-15px' }}

        >
          <List >
            {this.state.voiceMemoslist && this.state.voiceMemoslist.length > 0 &&
              this.state.voiceMemoslist.map((item: VoiceMemo) => {
                return (
                  <ListItem key={item.id} dense button>
                    <ListItemAvatar>
                      <Avatar>
                        <PlayCircleOutlineIcon data-testid="opendialog" onClick={() => this.handleDialogOpen(item)} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.attributes.filename}
                      secondary={
                        <>
                          <Typography variant="body2">{item.attributes.record_time}</Typography>
                          <Typography variant="body2">{item.attributes.created_at.replace("T", " ").substring(0, 19)}</Typography>
                        </>
                      } />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" data-testid="deleteConfirmdialog" onClick={() => this.openDeleteConfirmDialog(item.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              }
              )
            }
          </List>
        </InfiniteScroll>
      </div>)
  }

  audioPlayDialog = () => {
    return (<Dialog aria-labelledby="simple-dialog-title" PaperProps={{ style: webStyle.audioRoot }} open={this.state.showDialog}>
      <DialogTitle id="max-width-dialog-title">
        <IconButton aria-label="close" style={{ position: "absolute", right: "0px", top: "0px" }} data-testid="closedialog" onClick={() => { this.handleDialogClose() }} >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={webStyle.audioDialogContent}>
        <Typography variant="subtitle1" style={{ textAlign: "center" }} >{this.state.voiceMemo?.attributes?.filename}</Typography>
        <audio controls >
          <source src={`${baseURL}${this.state.voiceMemo?.attributes?.voice_record}`} />
        </audio>
      </DialogContent>
    </Dialog>)
  }

  confirmDialog = () => {
    return (
      <Dialog aria-labelledby="simple-dialog-title" PaperProps={{ style: webStyle.audioRoot }} data-testid="deleteModal" open={this.state.showErrorDialog}>
        {(this.state.dialogMode == "warning" || this.state.dialogMode == "saveconfirm" || this.state.dialogMode == "deleteconfirm") &&
          <DialogTitle id="max-width-dialog-title">
            <IconButton aria-label="close" style={{ position: "absolute", right: "0px", top: "0px" }} data-testid="closemessagedialog" onClick={() => { this.handleMessageDialogClose() }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        }
        <DialogContent style={webStyle.confirmDialogContent}>
          {this.state.dialogMode == "warning" && <Typography variant="subtitle1" align="center" >{configJSON.voiceMemoWarnMessage}</Typography>}
          {this.state.dialogMode == "maxlimit" && <Typography variant="subtitle1" align="center" >{configJSON.voiceMemoMaxlimitMessage}</Typography>}
          {(this.state.dialogMode == "saveconfirm" || this.state.dialogMode == "deleteconfirm") && <Typography data-testid="confirmMessage" variant="subtitle1" align="center">{this.state.dialogMode == "saveconfirm" ? configJSON.savingConfirmationMessage : configJSON.deleteConfirmationMessage}</Typography>}
        </DialogContent>
        {(this.state.dialogMode == "saveconfirm" || this.state.dialogMode == "deleteconfirm" || this.state.dialogMode == "maxlimit") &&
          <DialogActions style={{ justifyContent: "space-around", paddingTop: "0px", paddingBottom: "16px" }}>
            <Button style={{ backgroundColor: "red", color: "white", textTransform: "capitalize", borderRadius: "13px" }} variant="outlined" data-testid="discardBtn" onClick={() => { this.handleDiscardNoAction() }}>
              <>{this.state.dialogMode == "deleteconfirm" ? configJSON.btnNoTitle : configJSON.btnDiscardTitle}</>
            </Button>
            <Button style={{ backgroundColor: "green", color: "white", textTransform: "capitalize", borderRadius: "13px" }} variant="outlined" data-testid="confirmBtn" onClick={() => { this.handleSaveYesAction() }}>
              <>{this.state.dialogMode == "deleteconfirm" ? configJSON.btnYesTitle : configJSON.btnSaveTitle}</>
            </Button>
          </DialogActions>
        }
      </Dialog>
    )
  }
  recordActions = () => {
    return (
      <div style={{ boxShadow: "0px 1px 6px 2px dimgray", marginTop: '1rem' }}
      >
        <div
          className="col-md-6 col-md-offset-3" style={{
            display: "flex",
            justifyContent: "center", alignItems: "center"
          }}
        >
          <div style={{ display: "flex", paddingTop: "10px", paddingBottom: "10px" }}>
            {this.state.status == 'play' && <PlayArrowIcon style={{ paddingBottom: "0px", backgroundColor: "green", color: "white" }} data-testid="startrecord" onClick={() => this.startRecording()} />}
            {(this.state.status == 'pause' || this.state.status == 'resume') &&
              <>
                <div style={{ marginRight: "4rem", fontSize: "12px" }}>
                  {this.state.status === "pause" && <PauseIcon  style={{ backgroundColor: "green", color: "white" }} data-testid="pauserecord" onClick={() => this.pauseRecording()} />}
                  {this.state.status == 'resume' && <Avatar style={{ marginTop: "2px", width: "20px", height: "20px" }} data-testid="resumerecord" alt="Travis Howard" src={resumeIcon} onClick={() => this.resumeRecording()} />
                  }
                </div>
                <StopIcon style={{ marginLeft: "4rem", backgroundColor: "#e04f5f", color: "white" }} data-testid="openConfirmDialog" onClick={() => { this.openSaveConfirmDialog() }} />
              </>
            }
          </div>

        </div>
        {(this.state.status == 'pause' || this.state.status == 'resume') &&
          <div style={{ display: "block !important", textAlign: "center", paddingBottom: "6px" }}>
            <span className="hours">{this.state.hour > 9 ? this.state.hour : '0' + this.state.hour}</span>
            <span>:</span>
            <span className="minutes">{this.state.minute > 9 ? this.state.minute : '0' + this.state.minute}</span>
            <span>:</span>
            <span className="seconds">{this.state.updSecond > 9 ? this.state.updSecond : '0' + this.state.updSecond}</span>
          </div>
        }
      </div>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="md" style={webStyle.root}>
          <Typography variant="h6" display="block" gutterBottom>{configJSON.labelTitleText} </Typography>
          {this.VoicememoList(this.state.voiceMemoslist)}
          {this.recordActions()}
          {this.audioPlayDialog()}
          {this.confirmDialog()}
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    backgroundColor: "#e8e8e821"
  },
  audioRoot: { borderRadius: "10px" },
  audioDialogContent: { margin: "0px auto 54px" },
  confirmDialogContent: { "margin": "0px auto 0px" }

}
// Customizable Area End

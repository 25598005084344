export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const CossIcon = require("../assets/CossIcon.png");
export const PlayIcon = require('../assets/PlayIcon.png');
export const record = require('../assets/record.png');
export const RecordingIcon = require('../assets/RecordingIcon.png');
export const PlayButton = require('../assets/PlayButton.png');
export const PauseButton = require('../assets/PauseButton.png');
export const Cancle_Icon = require('../assets/Cancle_Icon.png');
export const Pause_Icon = require('../assets/Pause_Icon.png');
export const Start_Icon = require('../assets/Start_Icon.png');
export const Play = require('../assets/Play.png');
export const resumeIcon = require('../assets/resumeButton.png');
export const Start_Play = require('../assets/Start_Play.png');
export const Delete_Icon = require('../assets/Delete_Icon.png');
export const Stop_Icon = require('../assets/StopIcon.png');
export const Start_Recording = require('../assets/StartRecording.png');
